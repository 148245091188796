<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="6">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="'primary'"
            :class="'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="userData.avatar"
              :src="require(`@/assets/images/avatars/${userData.avatar}`)"
            ></v-img>
            <span
              class="font-weight-semibold text-5xl"
            >{{ avatarText(userData.first_name+" "+userData.last_name) }}</span>
          </v-avatar>

          <span class="mb-2">{{ userData.first_name+" "+userData.last_name }}</span>

          <v-chip
            label
            small
            :color="resolveUserRoleVariant(userData.role)"
            :class="`v-chip-light-bg text-sm font-weight-semibold ${resolveUserRoleVariant(role.name)}--text text-capitalize`"
          >
            {{ role.name }}
          </v-chip>
        </v-card-title>
        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Détails
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Nom d'utilisateur :</span>
              <span class="text--secondary">{{ userData.login }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Adresse Email:</span>
              <span class="text--secondary">{{ userData.email }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Status:</span>
              <span class="text--secondary">
                <v-chip
                  small
                  label
                  :color="resolveUserStatusVariant(userData.state)"
                  :class="`v-chip-light-bg ${resolveUserStatusVariant(userData.state)}--text font-weight-medium text-capitalize`"
                >
                  {{ resolveUserStatusNameVariant(userData.state) }}
                </v-chip>
              </span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Role:</span>
              <span class="text--secondary text-capitalize">{{ role.name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Contact:</span>
              <span class="text--secondary">+237 {{ userData.phone }}</span>
            </v-list-item>

<!--            <v-list-item-->
<!--              dense-->
<!--              class="px-0 mb-n2"-->
<!--            >-->
<!--              <span class="font-weight-medium me-2">Country:</span>-->
<!--              <span class="text&#45;&#45;secondary">{{ userData.country }}</span>-->
<!--            </v-list-item>-->
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6">
      <!-- edit profile dialog data -->
      <user-bio-edit
        :user-data="userData"
      ></user-bio-edit>
    </v-col>

  </v-row>
</template>

<script>
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import UserBioEdit from './UserBioEdit.vue'
import useUsersList from '../../user-list/useUsersList'
import Session from '@/helpers/session'


export default {
  components: {
    UserBioEdit,
  },
  setup() {

    const userData = JSON.parse(Session.get('userData'))
    const role = JSON.parse(localStorage.getItem('defaultRole'))

    console.log("userData", userData)
    console.log("role", role)

    const { resolveUserStatusVariant, resolveUserRoleVariant,resolveUserStatusNameVariant } = useUsersList()

    return {
      resolveUserStatusVariant,
      resolveUserRoleVariant,
      resolveUserStatusNameVariant,
      avatarText,
      kFormatter,

      userData,
      role,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
